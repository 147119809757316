import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import ButtonNew from '@/src/components/ButtonNew';
import { useWindowWidth } from '@hooks/windowHooks';
import { breakpoints } from '@hooks/useBreakpoints';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import { trackClickEvent } from '@utils/analytics';
import s from './FlexibleImageCTA.module.scss';

const propTypes = {
  content: PropTypes.shape({
    header: PropTypes.string,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string.isRequired,
    image: PropTypes.shape({}).isRequired,
  }).isRequired,
  analyticsParams: PropTypes.shape({
    verticalIndex: PropTypes.number.isRequired,
    horizontalIndexOffset: PropTypes.number,
  }),
};

const FlexibleImageCTA = ({ content, analyticsParams = {} }) => {
  const { header, cta, image, ctaUrl } = content;
  const { verticalIndex, componentRef } = useVerticalIndex();
  const router = useRouter();
  const { horizontalIndexOffset } = analyticsParams;
  const width = useWindowWidth();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(width < breakpoints.lg);
  }, [width]);

  const trackClick = () => {
    trackClickEvent(
      {
        destination_url: ctaUrl,
        label: 'Flexible Image CTA Clicked',
        location: 'FlexibleImageCTA',
        vertical_index: verticalIndex,
        horizontal_index: horizontalIndexOffset,
        content_name: header,
      },
      content,
    );
    router.push(ctaUrl);
  };

  return (
    <div
      onClick={() => trackClick()}
      data-testid="cta-link"
      onKeyDown={
        /* istanbul ignore next */ (e) => {
          if (e.key === 'Enter') {
            trackClick();
          }
        }
      }
      role="button"
      tabIndex={-1}
      ref={componentRef}
    >
      <div className={s.root} style={{ backgroundImage: `url(${image?.file?.url})` }}>
        {header && cta && (
          <div className={s.ctaContent} data-testid="cta-content">
            <div className={s.header}>{header}</div>
            <div className={s.cta}>
              <ButtonNew
                aria-label={cta}
                label={cta}
                theme="modern"
                level="primary"
                color="purple"
                size={isMobile ? 'small' : 'large'}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

FlexibleImageCTA.propTypes = propTypes;
export default FlexibleImageCTA;
